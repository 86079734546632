import React, { useState } from 'react';
import { navigate } from 'gatsby';
import slugify from 'slugify';

import Layout from '../components/furniture/Layout/Layout';
import { useFirebase } from 'gatsby-plugin-firebase';
import generateTitle from '../utils/generateTitle';

const AddUserName = ({ location }) => {
  const [userName, setUserName] = useState('');
  const [error, setError] = useState('');
  const title = generateTitle('Add Username', 'Profile');

  const [firebase, setFirebase] = useState();

  useFirebase(
    firebase => {
      setFirebase(firebase);
    },
    [firebase]
  );

  const handleSubmit = async e => {
    e.preventDefault();

    const db = firebase.firestore();
    const userId = firebase.auth().currentUser.uid;
    const slug = slugify(userName, { lower: true });

    const userRef = db.collection('users').doc(userId);
    const userNameRef = db.collection('userNames').doc(slug);

    db.runTransaction(transaction => {
      return transaction.get(userRef).then(user => {
        if (!user.data().userName) {
          transaction.set(
            userRef,
            {
              userName,
              slug,
            },
            { merge: true }
          );
          transaction.set(userNameRef, {
            userId,
          });
        } else {
          const currentUserName = user.data().slug;
          const currentUserNameRef = db
            .collection('userNames')
            .doc(currentUserName);
          // remove the old username
          transaction.delete(currentUserNameRef);
          transaction.set(
            userRef,
            {
              userName,
              slug,
            },
            { merge: true }
          );
          transaction.set(userNameRef, {
            userId,
          });
        }
        return true;
      });
    })
    .then(() => {
      navigate('/');
    })
    .catch(err => {
      console.error(err);
      setError('Unable to update username');
    });
  };

  return (
    <Layout location={location} pageTitle={title}>
      <h1>One last step!</h1>
      <p>Please add a username</p>
      {error && <p>{error}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          onChange={e => setUserName(e.target.value)}
          value={userName}
        />
        <input type="submit" value="Submit" />
      </form>
    </Layout>
  );
};

export default AddUserName;
